import React, { lazy, Suspense } from 'react'
import { useNodes } from 'reactflow'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import DataTooltip from '../../../../UI/DataTooltip'
import LoadingSpinner from '../../../../UI/LoadingSpinner'

const TestModal = lazy(
  () => import('./TestModal')
)
const PublishModal = lazy(
  () =>
    import('./PublishModal')
)

const TEST_PUBLISH_DISABLE_MSG =
  'You can not test or publish a scneario without any scenes. Start by creating your first scene!'

const TestPublishButtons = () => {
  const [, currentClient] = useCurrentUser()
  const nodes = useNodes().filter((node) => node.type === 'Scene')

  const getTestButton = () => (
    <button
      className="o-button o-button--royal o-button--auto-width text-normal text-bold mr-1"
      data-open="test-modal"
      disabled={nodes.length === 0}>
      Test
    </button>
  )

  const getPublishButton = (disabled) => {
    const getButton = (disabled) => (
      <button
        className="o-button o-button--success o-button--auto-width text-normal text-bold"
        data-open="publish-modal"
        disabled={disabled}>
        Publish
      </button>
    )

    if (disabled) return getButton(true)

    return (
      <>
        {currentClient?.canPublishScenarios ? (
          getButton()
        ) : (
          <DataTooltip
            position="bottom"
            title="You do not have publishing slots open to publish this scenario. Contact the owner of this workspace or go to settings to increase the number of publishing slots">
            {getButton(true)}
          </DataTooltip>
        )}
      </>
    )
  }

  return (
    <div className="flex-container align-middle">
      {nodes.length > 0 ? (
        <>
          {getTestButton()}
          {getPublishButton()}
        </>
      ) : (
        <>
          <DataTooltip position="bottom" title={TEST_PUBLISH_DISABLE_MSG}>
            {getTestButton()}
          </DataTooltip>
          <DataTooltip position="bottom" title={TEST_PUBLISH_DISABLE_MSG}>
            {getPublishButton(true)}
          </DataTooltip>
        </>
      )}

      {nodes.length > 0 && (
        <Suspense fallback={<LoadingSpinner />}>
          {/* Please do not remove the following wrapper div */}
          <div>
            <PublishModal />
          </div>

          <TestModal />
        </Suspense>
      )}
    </div>
  )
}

export default TestPublishButtons